class StartScreen extends HTMLElement {
  constructor() {
    super()

    this.scrollOffset = 1
    this.touchOffset = 1
    this.animationSpeed = -0.7
    this.wrapperEl = this.querySelector('.js-start-screen-wrapper')
    this.logoEl = document.querySelector('.js-site-header-logo')
    this.imageEl = this.querySelector('.js-start-screen-logo')
    this.logoHeaderEl = this.querySelector('.js-start-screen-logo-header')
    this.offsetImageWidth = this.logoEl.offsetWidth / this.imageEl.offsetWidth
    this.originalImageWidth = this.imageEl?.offsetWidth || 0
    this.originalImageHeight = this.imageEl?.offsetHeight || 0
    this.scale = 1
    this.originalImageTop = 1
    this.offsetImageTop = 1
    this.originalImageLeft = 1
    this.offsetImageLeft = 1

    this.wrapperEl.style.height = `${window.innerHeight}px`
    this.initPosition()

    window.addEventListener('resize', () => {
      this.logoEl = document.querySelector('.js-site-header-logo')
      this.imageEl = this.querySelector('.js-start-screen-logo')
      this.initPosition()
    })

    window.addEventListener('scroll', (e) => {
      if (this.logoHeaderEl) {
        const targetSection = document.querySelector('#MainContent>div')
        const sectionRect = targetSection.getBoundingClientRect()
        const thisRect = this.getBoundingClientRect()

        if (thisRect.top <= window.innerHeight && thisRect.bottom >= 0) {
          this.imageEl.style.opacity = 1
        } else {
          this.imageEl.style.opacity = 0
        }

        if (sectionRect.top <= window.innerHeight && sectionRect.bottom >= 0) {
          this.logoHeaderEl.style.transform = 'translateX(-200%)'
          this.logoEl.style.opacity = 1
        } else {
          this.logoHeaderEl.style.transform = 'translateX(0)'
          this.logoEl.style.opacity = 0
        }
      }
    })
  }

  initPosition () {
    const top = (window.innerHeight - this.imageEl?.getBoundingClientRect().height) / 2
    const left = (window.innerWidth - this.imageEl?.getBoundingClientRect().width) / 2

    this.originalImageTop = top
    this.originalImageLeft = left
    this.offsetImageTop = this.logoEl?.getBoundingClientRect().top / top
    this.offsetImageLeft = this.logoEl?.getBoundingClientRect().left / left
    this.imageEl.style.top = `${top}px`
    this.imageEl.style.left = `${left}px`
    this.imageEl.style.width = `${window.innerWidth - 44}px`

    if (window.innerWidth >= 1024) {
      this.imageEl.style.width = `${window.innerWidth - 342}px`
    }
  }
}

customElements.define('start-screen', StartScreen)
